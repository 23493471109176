import React, { useState, useEffect, useRef } from 'react'
import { useLocation } from 'react-router-dom'
import axios from 'axios'
import fileDownload from 'js-file-download'
import _ from 'lodash'
import moment from 'moment'
import classNames from 'classnames'
import history from '../../history'
import queryString from 'query-string'

import {
  Table,
  Typography,
  Select,
  Modal,
  Spin,
  Pagination,
  Checkbox,
  notification,
  message,
  Tag,
  Popconfirm,
  Tooltip, Icon
} from 'antd'

import {
  Button,
  FooterFixed
} from '../'

import { api } from '../../services'

import { findUsersByParams } from '../../services/api/user'
import forbiddenEmails from '../../constants/forbiddenEmails'

import {
  ColumnAddressee,
  ColumnActions,
  ColumnAttachments,
  ColumnStatus,
  ColumnEvents,
  ModalContactInvite
} from './components'

import { getDocumentStatus } from '../../helpers'

import { Layout } from './styled'

import { styleguide } from '../../constants'
import CustomDocumentDetailsPage from '../../views/CustomDocumentDetailsPage/CustomDocumentDetailsPage'
import ColumnCustomEvents from './components/ColumnCustomEvents'

const { colors } = styleguide

const defaultTableState = {
  selectedRowKeys: [],
  selectedRows: [],
  searchText: '',
  value: [],
  fetching: false,
  data: [],
  showModal: false,
  sorter: '',
  isFetching: false,
  loading: false,
  indeterminate: false,
  tariff_was_expired: false
}

const defaultParameterState = {
  selection_type: '',
  status: null,
  per_page: 15,
  page: 1,
  parameter: null,
  sort_by: 'created_at',
  sort_value: 'descend'
}

const defaultCustomDocumentsState = {
  data: {}
}

const { Text } = Typography
const { Option } = Select

export default ({
  getContacts,
  getCustomDocuments,
  deleteContact,
  getDocumentsWithParams,
  activeCompany,
  verifyFile,
  children,
  type,
  removeDocumentById,
  removeDocumentsByIds,
  documents,
  sendDocumentToUser,
  tableData,
  status,
  files,
  config,
  localSearch,
  queryParams = null,
  statuses,
  user,
  ...rest
}) => {
  const location = useLocation()

  const input = useRef(null)

  const [documentsStatuses, setDocumentsStatuses] = useState(statuses)
  const [customDocuments, setCustomDocuments] = useState({ ...defaultCustomDocumentsState })
  const [tableState, setTableState] = useState({ ...defaultTableState })
  const [parameterState, setParameterState] = useState({ ...defaultParameterState })
  const [modalContactInvite, setModalContactInvite] = useState(false)
  const [contactId, setContactId] = useState(null)

  const tablePerPage = window.localStorage.getItem('perPage')

  useEffect(() => {
    setTableState({
      ...tableState,
      selectedRowKeys: []
    })
  }, [location.search])

  useEffect(() => {
    if (activeCompany && status && type) {
      if (status === 'contacts') {
        getContacts({
          ...parameterState,
          status: null,
          selection_type: null,
          per_page: tablePerPage || 15,
          page: 1
        })
      } else if (status === 'customDocuments') {
        getCustomDocuments({
          ...parameterState,
          per_page: tablePerPage || 15,
          page: 1
        })
      } else {
        setParameterState({
          ...parameterState,
          status: status,
          selection_type: type,
          per_page: tablePerPage || 15,
          page: 1
        })
      }
    }
  }, [activeCompany, status, type])

  useEffect(() => {
    if (status === 'contacts') {
      getContacts({
        ...parameterState,
        parameter: config.search
      })
    }

    if ((parameterState.status !== status) && (parameterState.status !== null)) {
      getDocumentsWithParams(activeCompany, {
        ...parameterState,
        status: status
      })
    }

    if (parameterState.status && !queryParams) {
      config.search &&
      getDocumentsWithParams(activeCompany, {
        ...parameterState,
        parameter: config.search
      })
    }

    if (!_.isEmpty(queryParams)) {
      getDocumentsWithParams(activeCompany, {
        ...queryParams,
        per_page: parameterState.per_page,
        page: parameterState.page
      })
    }
  }, [
    parameterState.per_page,
    parameterState.page,
    parameterState.parameter,
    parameterState.sort_by,
    parameterState.sort_value,
    config.search
  ])

  useEffect(() => {
    if (status === 'contacts') {
      getContacts({
        ...parameterState,
        parameter: localSearch
      })
    }
  }, [localSearch])

  useEffect(() => {
    if (parameterState.status && status !== 'all') {
      if (status === parameterState.status) {
        getDocumentsWithParams(activeCompany, parameterState)
      }
    }
  }, [parameterState.status, parameterState.per_page, parameterState.page])

  useEffect(() => {
    setTableState({
      ...defaultTableState,
      tariff_was_expired: user?.active_company_object?.tariff_was_expired
    })
  }, [user])

  useEffect(() => {
    const checkMaxPageSize = () => {
      if (status === 1 || status === 3 || status === 'all' || status === 'customDocuments') {
        if (tablePerPage > 60) {
          window.localStorage.setItem('perPage', '15')
          setParameterState({
            ...parameterState,
            per_page: 15
          })
        }
      }
    }
    checkMaxPageSize()
  }, [status])

  const downloadReceipt = (id, name, type) => {
    setTableState({
      ...tableState,
      isFetching: true
    })

    let auth = window.localStorage.getItem('authToken') != null
      ? window.localStorage.getItem('authToken')
      : window.sessionStorage.getItem('authToken')

    axios.get(`${process.env.REACT_APP_BASE_URL}/receipt/${type}/${id}`, {
      'responseType': 'arraybuffer',
      headers: {
        'Authorization': 'Bearer ' + auth,
        'Access-Control-Expose-Headers': 'Content-Disposition,X-Suggested-Filename'
      }
    })
      .then(({ data }) => {
        if (data) {
          fileDownload(data, `${name}.${type}`)

          setTableState({
            ...tableState,
            isFetching: false
          })
        }
      })
      .catch(error => {
        message.error(error)
      })
  }

  const onSelectChange = (selectedRowKeys, selectedRows) => {
    setTableState({
      ...tableState,
      indeterminate: !!selectedRowKeys.length,
      selectedRows,
      selectedRowKeys
    })
  }

  const handleRemove = () => {
    if (tableState.selectedRowKeys.length === 0) {
      message.error('Нет выбраных документов!')

      return null
    }

    if (tableState.selectedRowKeys.length > 1) {
      const obj = {
        ids: tableState.selectedRowKeys
      }

      removeDocumentsByIds(obj)
        .then(response => {
          if (response.success) {
            message.success('Документы перемещены в архив')
            setTableState({ ...defaultTableState })
          } else {
            throw new Error(response.error)
          }
        })
        .catch(error => {
          message.error(error.message)
        })
    } else {
      removeDocumentById(tableState.selectedRowKeys[0], type)
        .then(response => {
          if (response.success) {
            message.success('Документ перемещен в архив')
            setTableState({ ...defaultTableState })
          } else {
            throw new Error(response.error)
          }
        })
        .catch(error => {
          message.error(error.message)
        })
    }
  }

  const fetchUser = _.debounce(v => {
    if (v.length > 2) {
      setTableState({
        ...tableState,
        fetching: true
      })

      findUsersByParams(v)
        .then(({ data }) => {
          const dataIds = tableState.data.map(i => i.key)

          const dataArray = data.data
            .map(user => ({
              label: `${user.user_data.email} (УНП:${user.company_data.company_number}; Компания:${user.company_data.name})`,
              key: `${user.id}`
            }))
            .filter(i => !dataIds.includes(i.key))

          setTableState({
            ...tableState,
            data: [...tableState.data, ...dataArray],
            fetching: false
          })
        })
        .catch(error => {
          message.error(error.message)
        })
    }
  }, 200)

  const handleSelect = v => {
    setTableState({
      ...tableState,
      data: v,
      value: v
    })
  }

  const hideOptions = () => {
    input.current.focus()
  }

  const openModal = () => {
    if (tableState.selectedRowKeys.length === 0) {
      message.error('Нет выбраных документов!')

      return null
    }

    setTableState({
      ...tableState,
      showModal: true
    })
  }

  const asyncSendMessage = async (id, users) => {
    await sendDocumentToUser({ document_ids: [id], user_company_id: users })
  }

  const sendToUser = () => {
    if (tableState.value.filter(i => forbiddenEmails.includes(i.key)).length) {
      notification.error({
        message: 'Отправка/перенаправление по реквизиту УНП для данного адресата запрещено. Укажите точный адрес (E-mail) получателя.'
      })

      return false
    }

    const docsDataToUser = {
      messages: tableData.data
        .filter(i => tableState.selectedRowKeys.includes(i.id)).map(i => i.document_id),
      user_company_id: JSON.stringify(tableState.value.map(i => i.key))
    }

    let chain = Promise.resolve()

    const uniqueMessagesIds = [...new Set(docsDataToUser.messages)]

    uniqueMessagesIds.forEach(id => {
      chain = chain.then(() => asyncSendMessage(id, docsDataToUser.user_company_id))
    })

    chain.finally(() => {
      window.location.reload()
    })
  }

  const handleChangePerPage = value => {
    window.localStorage.setItem('perPage', value)

    setParameterState({
      ...parameterState,
      per_page: value
    })
  }

  const handleChangePage = page => {
    setParameterState({
      ...parameterState,
      page: page
    })
  }

  const handleTableChange = (pagination, filters, sorter) => {
    setParameterState({
      ...parameterState,
      sort_by: sorter.columnKey,
      sort_value: sorter.order
    })
  }

  const downloadLink = preview => {
    let token = window.localStorage.getItem('authToken') || window.sessionStorage.getItem('authToken')

    axios.get(preview, {
      'responseType': 'arraybuffer',
      headers: {
        'Authorization': 'Bearer ' + token,
        'Access-Control-Expose-Headers': 'Content-Disposition,X-Suggested-Filename'
      }
    })
      .then(({ data }) => {
        if (data) {
          fileDownload(data, `file.pdf`)
          message.success('Файл успешно загружен!')
        }
      })
      .catch(error => {
        message.error(error.message)
      })
  }

  const proccesFilesForVerifyFile = async (bool, files) => {
    for (const file of files) {
      if (bool || file.status.status_data.id === 3) {
        const base64 = await api.files.getBase64File(file.id)

        try {
          const sertificationObject = await window.signProcess(base64.data.data.encoded_base64_file, file.hash_for_sign, true)

          const verifiedData = {
            id: file.id,
            hash: sertificationObject.signedData,
            data: sertificationObject.verifiedData,
            hash_for_sign: sertificationObject.hex,
            status: bool ? null : 5
          }

          const confirm = await api.documents.attachmentSignCanConfirm({
            key: sertificationObject.verifiedData.key,
            attachment_id: file.id
          })

          if (confirm.data.success) {
            api.files.verifyFile(verifiedData)
          }
        } catch (error) {
          notification['error']({
            message: error.message
          })
        }
      }
    }
  }

  const multipleVerify = () => {
    setTableState({
      ...tableState,
      loading: true
    })

    proccesMessageForVerifyFiles(tableData.data.filter(i => tableState.selectedRowKeys.includes(i.id)))
      .then(() => {
        setTableState({ ...defaultTableState })

        notification.success({
          message: 'Подпись завершена! закройте окно и перезагрузите страницу.'
        })
      })
  }

  const reloadPlugin = () => {
    window.pluginLoaded()

    multipleVerify()
  }

  const proccesMessageForVerifyFiles = async messages => {
    for (const [index, message] of messages.entries()) {
      await proccesFilesForVerifyFile(message.can_be_signed, message.document.attachments)
    }
  }

  const handleChangeIndeterminate = e => {
    if (e.target.checked) {
      setTableState({
        ...tableState,
        indeterminate: false,
        selectedRowKeys: []
      })
    }
  }

  const handleVisibleModalInvite = id => {
    setModalContactInvite(true)
    setContactId(id)
  }

  const handleRemoveContacts = () => {
    tableState.selectedRowKeys.forEach(id => {
      deleteContact(id, history)
    })
  }

  const handleSendMessages = () => {
    const selectedRows = tableState.selectedRows.map(item => item.contact_data.user_email)

    history.push(`/new-document?${queryString.stringify({ contacts: selectedRows }, { arrayFormat: 'bracket' })}`)
  }

  const columnsCustomDocuments = [
    {
      title: 'Название',
      key: 'name',
      sorter: (a, b) => b.name.length - a.name.length,
      sortDirections: ['descend'],
      render: record => (
        <Layout.Link to={`/custom-documents/${record.id}`}>
          {record.name}
        </Layout.Link>
      )
    },
    {
      title: 'Получатель',
      key: 'recipient',
      render: record => (
        <Layout.Link to={`/custom-documents/${record.id}`}>
          {record.data.recipient}
        </Layout.Link>
      )
    },
    {
      title: 'Дата добавления',
      key: 'created_at',
      sorter: true,
      defaultSortOrder: 'descend',
      render: record =>
        <Text>{moment.utc(record.created_at, 'YYYY-MM-DD HH:mm:ss').format('DD.MM.YYYY, HH:mm:ss')}</Text>
    },
    {
      title: 'Тип',
      key: 'type',
      render: record => record.type.name
    },
    {
      title: 'Сумма',
      key: 'price',
      align: 'center',
      render: record => record.data.fields.reduce((acc, el) => acc + el.field_price, 0),
      sorter: (a, b) => a.field_price - b.field_price,
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: 'Валюта',
      key: 'currency',
      align: 'center',
      render: record => record.data.fields.map(f => f.field_currency),
      sorter: true,
      defaultSortOrder: 'descend',
    },
    {
      title: 'Действия',
      key: 'events',
      align: 'right',
      render: record => (
        <ColumnCustomEvents
          onSand={() => {}}
          onRoamingSend={() => {}}
          onShare={() => {}}
          onCreateRule={() => {}}
          onDownload={() => downloadLink(record.preview)}
          details={record}
        />
      )
    },
  ]

  const columnsContacts = [
    {
      title: 'Cтатус',
      key: 'status',
      width: 80,
      render: record => {
        let status = {
          tooltip: 'Контакта нет в сервисе',
          color: colors.primary
        }

        if (record.contact_data.secure_keys_count > 0) {
          status = {
            tooltip: 'Контакт есть в сервисе с ЭЦП',
            color: colors.green
          }
        } else if (record.contact_data.user_data.is_emailed) {
          status = {
            tooltip: 'Контакт есть в сервисе по Email',
            color: colors.orange
          }
        }

        return (
          <Tooltip
            title={status.tooltip}
            placement="top"
            arrowPointAtCenter
          >
            <Layout.Column.ContactStatus color={status.color}/>
          </Tooltip>
        )
      }
    },
    {
      title: 'Email',
      key: 'email',
      sorter: true,
      defaultSortOrder: 'descend',
      render: record => (
        <Layout.Link to={`/contacts/${record.id}`}>
          {record.contact_data.user_data.email}
        </Layout.Link>
      )
    },
    {
      title: 'УНП',
      key: 'unp',
      sorter: true,
      defaultSortOrder: 'descend',
      render: record => +record.contact_data.company_data.company_number !== 0
        ? <Tag color={colors.green}>{record.contact_data.company_data.company_number}</Tag>
        : record.temp_company_number !== null
          ? <Tag color={colors.primary}>{record.temp_company_number}</Tag>
          : 'Отсутствует'
    },
    {
      title: 'Телефон',
      key: 'phone',
      render: record => record.contact_data.user_data.phone
    },
    {
      title: 'Краткое наименование',
      key: 'name',
      render: record => record.contact_data.company_data.name
    },
    {
      title: 'Дата добавления',
      key: 'created_at',
      sorter: true,
      defaultSortOrder: 'descend',
      render: record =>
        <Text>{moment.utc(record.created_at, 'YYYY-MM-DD HH:mm:ss').format('DD.MM.YYYY, HH:mm:ss')}</Text>
    },
    {
      key: 'events',
      align: 'right',
      render: record => (
        <ColumnEvents
          onInvite={() => handleVisibleModalInvite(record.contact_data.id)}
          onEdit={() => history.push(`/contacts/${record.id}`)}
          onDelete={() => deleteContact(record.id, history)}
          details={record}
        />
      )
    }
  ]

  const columns = [
    {
      title: (status === 1 || status === 3 || status === 10) ? 'Получатель' : 'Отправитель',
      key: type,
      render: record => (
        <ColumnAddressee
          status={status}
          record={record}
        />
      )
    },
    {
      title: (status === 9 ? 'УНП' : ''),
      key: 'unp'
    },
    {
      title: (status === 10 ? 'ЭЦП' : ''),
      key: 'file-status',
      render: record => {
        if (status === 10) {
          return <ColumnStatus status={!!record.document.applied_attachments_count}/>
        } else {
          return ''
        }
      }
    },
    {
      title: 'Тема',
      key: 'descr',
      render: record => (
        <Layout.Column.Theme to={`/documents/${record.id}`}>
          {record.document.name}
        </Layout.Column.Theme>
      )
    },
    {
      title: 'Вложения',
      key: 'attachments',
      align: 'center',
      render: record => (
        <ColumnAttachments
          statuses={documentsStatuses.attachment_statuses}
          files={record.document.attachments_status_count}
        />
      )
    },
    {
      title: 'Дата',
      key: 'created_at',
      align: 'right',
      sorter: true,
      defaultSortOrder: 'descend',
      render: record =>
        <Text>{moment.utc(record.created_at, 'YYYY-MM-DD HH:mm:ss').format('DD.MM.YYYY, HH:mm:ss')}</Text>
    },
    {
      title: (status === 9 ? 'ЭЦП' : '')
    },
    {
      title: 'Квитанция',
      key: 'receipt',
      render: record => {
        const {
          id,
          document
        } = record

        return (
          <ColumnActions
            onDownloadPDF={() => downloadReceipt(id, document.name, 'pdf')}
            onDownloadXml={() => downloadReceipt(id, document.name, 'xml')}
          />
        )
      }
    }
  ]

  const columnsAll = [
    {
      title: 'Папка',
      dataIndex: 'actual_status',
      key: 'actual_status',
      render: actual_status => <Tag color={colors.primary}>{getDocumentStatus(`${actual_status}`)}</Tag>
    },
    {
      title: 'Получатель / Отправитель',
      key: type,
      render: record => (
        <ColumnAddressee
          status={status}
          record={record}
        />
      )
    },
    {
      title: 'Тема',
      key: 'descr',
      render: record => (
        <Layout.Column.Theme to={`/documents/${record.id}`}>
          {record.document.name}
        </Layout.Column.Theme>
      )
    },
    {
      title: 'Вложения',
      key: 'attachments',
      align: 'center',
      render: record => (
        <ColumnAttachments
          statuses={documentsStatuses.attachment_statuses}
          files={record.document.attachments_status_count}
        />
      )
    },
    {
      title: 'Дата',
      key: 'created_at',
      align: 'right',
      render: record =>
        <Text>{moment.utc(record.created_at, 'YYYY-MM-DD HH:mm:ss').format('DD.MM.YYYY, HH:mm:ss')}</Text>
    },
    {
      title: 'Квитанция',
      key: 'receipt',
      render: record => {
        const {
          id,
          document
        } = record

        return (
          <ColumnActions
            onDownloadPDF={() => downloadReceipt(id, document.name, 'pdf')}
            onDownloadXml={() => downloadReceipt(id, document.name, 'xml')}
          />
        )
      }
    }
  ]

  const columnsArchive = [
    {
      title: 'Адресат',
      key: type,
      render: record => (
        <ColumnAddressee
          status={status}
          record={record}
        />
      )
    },
    {
      title: 'Тема',
      key: 'descr',
      render: record => (
        <Layout.Column.Theme to={`/documents/${record.id}`}>
          {record.document.name}
        </Layout.Column.Theme>
      )
    },
    {
      title: 'Добавлено из',
      key: 'status',
      render: () => 'Архив'
    },
    {
      title: 'Вложения',
      key: 'attachments',
      align: 'center',
      render: record => (
        <ColumnAttachments
          statuses={documentsStatuses?.attachment_statuses}
          files={record.document.attachments_status_count}
        />
      )
    },
    {
      title: 'Дата',
      key: 'created_at',
      align: 'right',
      sorter: true,
      defaultSortOrder: 'descend',
      render: record =>
        <Text>{moment.utc(record.created_at, 'YYYY-MM-DD HH:mm:ss').format('DD.MM.YYYY, HH:mm:ss')}</Text>
    },
    {
      title: 'Квитанция',
      key: 'receipt',
      render: record => {
        const {
          id,
          document
        } = record

        return (
          <ColumnActions
            onDownloadPDF={() => downloadReceipt(id, document.name, 'pdf')}
            onDownloadXml={() => downloadReceipt(id, document.name, 'xml')}
          />
        )
      }
    }
  ]

  const columnsPartySources = [
    {
      title: 'Получатель',
      key: type,
      render: record => (
        <ColumnAddressee
          status={status}
          record={record}
        />
      )
    },
    {
      title: (status === 10 ? 'ЭЦП' : ''),
      key: 'file-status',
      render: record => {
        if (status === 10) {
          return <ColumnStatus status={!!record.document.applied_attachments_count}/>
        } else {
          return ''
        }
      }
    },
    {
      title: 'Тема',
      key: 'descr',
      render: record => (
        <Layout.Column.Theme to={`/documents/${record.id}`}>
          {record.document.name}
        </Layout.Column.Theme>
      )
    },
    {
      title: 'Вложения',
      key: 'attachments',
      align: 'center',
      render: record => (
        <ColumnAttachments
          statuses={documentsStatuses?.attachment_statuses}
          files={record.document.attachments_status_count}
        />
      )
    },
    {
      title: 'Дата',
      key: 'created_at',
      align: 'right',
      sorter: true,
      defaultSortOrder: 'descend',
      render: record =>
        <Text>{moment.utc(record.created_at, 'YYYY-MM-DD HH:mm:ss').format('DD.MM.YYYY, HH:mm:ss')}</Text>
    },
    {
      title: 'Квитанция',
      key: 'receipt',
      render: record => {
        const {
          id,
          document
        } = record

        return (
          <ColumnActions
            onDownloadPDF={() => downloadReceipt(id, document.name, 'pdf')}
            onDownloadXml={() => downloadReceipt(id, document.name, 'xml')}
          />
        )
      }
    }
  ]

  const columnsStatuses = [
    {
      title: (status === 1 || status === 3) ? 'Получатель' : 'Отправитель',
      key: type,
      render: record => (
        <ColumnAddressee
          status={status}
          record={record}
        />
      )
    },
    {
      title: 'Тема',
      key: 'descr',
      render: record => (
        <Layout.Column.Theme to={`/documents/${record.id}`}>
          {record.document.name}
        </Layout.Column.Theme>
      )
    },
    {
      title: 'Вложения',
      key: 'attachments',
      align: 'center',
      render: record => (
        <ColumnAttachments
          statuses={documentsStatuses?.attachment_statuses}
          files={record.document.attachments_status_count}
        />
      )
    },
    {
      title: 'Добавлено из',
      key: 'status_name',
      dataIndex: 'status_name'
    },
    {
      title: 'Дата',
      key: 'created_at',
      align: 'right',
      sorter: true,
      defaultSortOrder: 'descend',
      render: record =>
        <Text>{moment.utc(record.created_at, 'YYYY-MM-DD HH:mm:ss').format('DD.MM.YYYY, HH:mm:ss')}</Text>
    },
    {
      title: (status === 9 ? 'ЭЦП' : '')
    },
    {
      title: 'Квитанция',
      key: 'receipt',
      render: record => {
        const {
          id,
          document
        } = record

        return (
          <ColumnActions
            onDownloadPDF={() => downloadReceipt(id, document.name, 'pdf')}
            onDownloadXml={() => downloadReceipt(id, document.name, 'xml')}
          />
        )
      }
    }
  ]

  const handleTableColumns = () => {
    if (location.pathname === '/attachments') {
      return columnsStatuses
    }

    if (status === 1 || status === 3 || status === 10) {
      return columns
    } else if (status === 4) {
      return columnsArchive
    } else if (status === 9) {
      return columnsPartySources
    } else if (status === 'all') {
      return columnsAll
    } else if (status === 'contacts') {
      return columnsContacts
    } else if (status === 'customDocuments') {
      return columnsCustomDocuments
    } else {
      return columns
    }
  }

  return (
    <Layout>
      <ModalContactInvite
        id={contactId}
        visible={modalContactInvite}
        onCancel={() => setModalContactInvite(false)}
      />

      <Spin spinning={tableState.isFetching}>
        <Table
          rowKey="id"
          rowSelection={status !== 'customDocuments' && {
            selectedRowKeys: tableState.selectedRowKeys,
            onChange: onSelectChange
          }}
          rowClassName={record => record.is_read === 0 ? 'unread' : ''}
          locale={{ emptyText: 'Нет данных' }}
          columns={handleTableColumns()}
          dataSource={tableData ? tableData.data : []}
          pagination={false}
          onChange={handleTableChange}
          {...rest}
        >
          {children}
        </Table>
      </Spin>

      <FooterFixed>
        {tableState.selectedRowKeys.length ? (
          <>
            <Layout.Selected>
              <Checkbox
                indeterminate={tableState.indeterminate}
                onChange={handleChangeIndeterminate}
                checked={false}
              >
                Снять выделение
              </Checkbox>

              <Layout.Selected.Count>Выделено: {tableState.selectedRowKeys.length}</Layout.Selected.Count>
            </Layout.Selected>

            {status !== 'contacts' ? (
              <Layout.Actions>
                <Layout.Actions.Item>
                  <Button
                    type="link"
                    onClick={() => openModal()}
                    disabled={tableState.tariff_was_expired}
                    ghost={tableState.tariff_was_expired}
                  >
                    <Icon type="export" style={tableState.tariff_was_expired ? { color: 'gray' } : {}}/>

                    Отправить выделенное
                  </Button>
                </Layout.Actions.Item>

                <Layout.Actions.Item>
                  <Button
                    type="link"
                    icon="edit"
                    onClick={reloadPlugin}
                  >
                    Групповое подписание
                  </Button>
                </Layout.Actions.Item>

                <>
                  {status !== 4 && (
                    <Layout.Actions.Item>
                      <Popconfirm
                        title="Занести в архив?"
                        okText="Да, занести"
                        cancelText="Отмена"
                        onConfirm={handleRemove}
                      >
                        <Button
                          type="link"
                          icon="delete"
                        >
                          В архив
                        </Button>
                      </Popconfirm>
                    </Layout.Actions.Item>)}
                </>
              </Layout.Actions>
            ) : (
              <Layout.Actions>
                <Layout.Actions.Item>
                  <Button
                    type="link"
                    icon="edit"
                    onClick={handleSendMessages}
                  >
                    Написать сообщение
                  </Button>
                </Layout.Actions.Item>

                <Layout.Actions.Item>
                  <Popconfirm
                    title="Удалить контакты?"
                    okText="Да, удалить"
                    cancelText="Отмена"
                    onConfirm={handleRemoveContacts}
                  >
                    <Button
                      type="link"
                      icon="delete"
                    >
                      Удалить
                    </Button>
                  </Popconfirm>
                </Layout.Actions.Item>
              </Layout.Actions>
            )}
          </>
        ) : (
          <Layout.Pagination className="ui-custom-pagination">
            <Layout.Pagination.List>
              <Layout.Pagination.Label>На странице</Layout.Pagination.Label>

              <Layout.Pagination.Item
                onClick={() => handleChangePerPage(15)}
                active={tablePerPage === '15'}
                className={classNames({ 'ui-custom-pagination--active': tablePerPage === '15' })}
              >
                15
              </Layout.Pagination.Item>

              <Layout.Pagination.Item
                onClick={() => handleChangePerPage(30)}
                active={tablePerPage === '30'}
                className={classNames({ 'ui-custom-pagination--active': tablePerPage === '30' })}
              >
                30
              </Layout.Pagination.Item>

              <Layout.Pagination.Item
                onClick={() => handleChangePerPage(60)}
                active={tablePerPage === '60'}
                className={classNames({ 'ui-custom-pagination--active': tablePerPage === '60' })}
              >
                60
              </Layout.Pagination.Item>

              {(status === 'contacts' || status === 4 || status === 9 || status === 10) && (
                <>
                  <Layout.Pagination.Item
                    onClick={() => handleChangePerPage(120)}
                    active={tablePerPage === '120'}
                    className={classNames({ 'ui-custom-pagination--active': tablePerPage === '120' })}
                  >
                    120
                  </Layout.Pagination.Item>
                  <Layout.Pagination.Item
                    onClick={() => handleChangePerPage(500)}
                    active={tablePerPage === '500'}
                    className={classNames({ 'ui-custom-pagination--active': tablePerPage === '500' })}
                  >
                    500
                  </Layout.Pagination.Item>
                  <Layout.Pagination.Item
                    onClick={() => handleChangePerPage(1000)}
                    active={tablePerPage === '1000'}
                    className={classNames({ 'ui-custom-pagination--active': tablePerPage === '1000' })}
                  >
                    1000
                  </Layout.Pagination.Item>
                </>
              )}
            </Layout.Pagination.List>

            <Pagination
              current={parameterState.page}
              total={tableData && !isNaN(Math.ceil(tableData.total / +tableData.per_page) * 10) ? Math.ceil(tableData.total / +tableData.per_page) * 10 : 0}
              onChange={handleChangePage}
              hideOnSinglePage
              simple
            />
          </Layout.Pagination>
        )}
      </FooterFixed>

      <Modal
        title={null}
        visible={tableState.showModal}
        closable={false}
        footer={null}
      >
        <Text>Получатели:</Text>

        <input
          type="text"
          ref={input}
          style={{
            opacity: 0,
            width: '100%',
            height: 0
          }}
        />

        <Select
          mode="tags"
          labelInValue
          tokenSeparators={[',']}
          value={tableState.value}
          filterOption={false}
          notFoundContent={tableState.fetching ? <Spin size="small"/> : null}
          onSearch={fetchUser}
          onChange={handleSelect}
          onSelect={hideOptions}
          style={{ width: '100%' }}
        >
          {tableState.data.map(element => <Option key={element.key}>{element.label}</Option>)}
        </Select>

        <Button
          type="primary"
          onClick={sendToUser}
          style={{ marginTop: 20 }}
        >
          Отправить
        </Button>

        <Button
          type="primary"
          onClick={() => setTableState({ ...tableState, showModal: false })}
          style={{ marginLeft: 20 }}
          ghost
        >
          Отмена
        </Button>
      </Modal>
    </Layout>
  )
}
