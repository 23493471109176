import React, { useState } from 'react'

import { Modal } from 'antd'

import { CompanyCreate } from '../../../'

import { Connect } from './styled'

export default () => {
  const [modalVisible, setModalVisible] = useState(false)

  return (
    <>
      <Connect onClick={() => setModalVisible(true)}>
        Подключить ЭЦП
      </Connect>

      <Modal
        title="Подключение ЭЦП"
        visible={modalVisible}
        width={600}
        closable={false}
        footer={null}
      >
        <CompanyCreate
          onCancel={() => setModalVisible(false)}
          redirect
        />
      </Modal>
    </>
  )
}
