import React from 'react'
import { lighten } from 'polished'
import { styleguide } from './constants'
import { message, Typography } from 'antd'

const { colors } = styleguide

const {
  Title,
  Paragraph
} = Typography

export const getTimeStamp = (time) => {
  return Math.round(new Date(time).getTime() / 1000)
}

export const getFileName = (url) => {
  return url.substring(url.lastIndexOf('/') + 1)
}

export const checkTarification = activeTarif => {
}

export const getDocumentStatus = id => {
  switch (id) {
    case '2':
      return 'Входящие'
    case '11':
      return 'Входящие (включая УНП)'
    case '3':
      return 'Отправленные'
    case 'exclude_chain':
      return 'Отправленные (включая цепочки)'
    case '1':
      return 'Черновики'
    case '10':
      return 'Сообщения реестра (не отправленные)'
    case '9':
      return 'Сторонние источники (не отправленные)'
    case '4':
      return 'Архив'
    default:
      return ''
  }
}

export const getAttachmentStatus = id => {
  switch (id) {
    case 1:
      return {
        color: colors.light
      }
    case 2:
      return {
        color: colors.orange
      }
    case 3:
      return {
        color: lighten(0.18, colors.green)
      }
    case 4:
      return {
        color: lighten(0.18, colors.orange)
      }
    case 5:
      return {
        color: colors.green
      }
    case 6:
      return {
        color: colors.red
      }
    default:
      return {
        color: colors.light
      }
  }
}

export const getGeneralAlert = (coBrand, activeCompany) => {
  const {
    tariff_was_expired,
    is_dummy,
    is_blocked,
    is_owner,
    owner_phone_number
  } = activeCompany

  const { tarification_id } = activeCompany.tarification

  const alertGeneral = (
    <>
      <Title level={3}>Уважаемый пользователь!</Title>

      <Paragraph>Поскольку по окончании демо-периода Вами не активирован ни один из постоянных пакетов услуг,
        возможность создания и отправки (перенаправления) сообщений и документов отключена.</Paragraph>
      <Paragraph>РАБОТА С ВХОДЯЩИМИ ПО-ПРЕЖНЕМУ БЕСПЛАТНА</Paragraph>
      <Paragraph>Работа со ВХОДЯЩИМИ документами – их получение/сохранение, подписание и согласование по прежнему
        доступна и для Вас абсолютно бесплатна.</Paragraph>
      <Paragraph>По вопросам подбора оптимального <a href="https://quidox.by/prices">тарифа</a> Вы можете связаться с
        нами по указанным <a href="https://quidox.by/#callback"> контактам</a>.</Paragraph>
    </>
  )

  if (!coBrand) {
    return alertGeneral
  }

  if (
    (tariff_was_expired && is_dummy === 1) ||
    (tariff_was_expired && is_dummy === 0 && tarification_id === 9) ||
    tarification_id === 13
  ) {
    return 'Внимание! Для продолжения пользования сервисом подключите услугу линейки "МТС SmartDoc".'
  }

  if (is_blocked && is_dummy === 0 && !is_owner) {
    return `Внимание! Предоставление сервиса" МТС SmartDoc" на тарификационном номере ${owner_phone_number} и всех пользователей Компании заблокировано!`
  }

  if (is_owner) {
    return 'Внимание! Вы израсходовали ваш пакет услуг. Чтобы продолжить пользование сервисом, пожалуйста, подключите новый пакет услуг на следующие 30 дней. При нажатии на кнопку "Перейти к продлению тарифа" стоимость пакета с учетом НДС спишется с лицевого счета вашего абонентского номера.'
  } else {
    return `Внимание! Вы израсходовали ваш пакет услуг. Чтобы продолжить пользоваться сервисом, пожалуйста, подключите новый пакет услуг на следующие 30 дней на номере ${owner_phone_number}.`
  }
}

export const updateAlert = () => {

  return (
    <>
      <Title level={3}>Уважаемый пользователь!</Title>
      <Paragraph>С 18.00 Пт (сегодня) по 11.00 Пн (24.03.2025) будут проводиться технические работы по оптимизации работы сервиса. Мы постараемся минимизировать время недоступности сервиса.</Paragraph>
    </>
  )
}

export const messageErrorCert = () => {
  message.error(<>Списки отозванных сертификатов (СОСы) устарели.<br/>Обновите их в Персональном менеджере сертификатов
    в пункте меню "Сервис".<br/>Как обновить СОСы подробно описано <a href="https://quidox.by/settings-downloads"
                                                                      target="_blank">ТУТ</a></>, 5)
}

export const attributesKeys = () => {
  return [
    {
      label: 'Страна',
      value: '2.5.4.6'
    },
    {
      label: 'Область',
      value: '2.5.4.8'
    },
    {
      label: 'Населенный пункт',
      value: '2.5.4.7'
    },
    {
      label: 'Адрес',
      value: '2.5.4.9'
    },
    {
      label: 'Наименование организации владельца открытого ключа',
      value: '2.5.4.10'
    },
    {
      label: 'Должность',
      value: '2.5.4.12'
    },
    {
      label: 'Адрес электронной почты',
      value: '1.2.840.113549.1.9.1'
    },
    {
      label: 'Данные из документа, удостоверяющего личность',
      value: '1.2.112.1.2.1.1.1.1.1'
    },
    {
      label: 'Уникальный номер налогоплательщика (УНП)',
      value: '1.2.112.1.2.1.1.1.1.2'
    },
    {
      label: 'Регистрационный номер плательщика ФСЗН',
      value: '1.2.112.1.2.1.1.1.4.1'
    },
    {
      label: 'Место работы и должность (физ. лица)',
      value: '1.2.112.1.2.1.1.5.1'
    },
    {
      label: 'Место работы и должность (подразделение)',
      value: '1.2.112.1.2.1.1.5.2'
    },
    {
      label: 'Регистрационный номер плательщика',
      value: '1.3.6.1.4.1.12656.4.1'
    }
  ]
}
